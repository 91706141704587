<div class="container">
    <mat-card class="main-card" matRipple>
        <mat-card-title class="title">Contact Our Office</mat-card-title>
        <form (ngSubmit)="this.submit()">
            <mat-form-field class="">
                <input
                    placeholder="Subject"
                    [formControl]="subjectFormControl"
                    matInput/>
                <!-- <span class="label">Subject</span> -->
            </mat-form-field>
            <mat-form-field class="">
                <input
                    type="email"
                    placeholder="Email"
                    [formControl]="emailFormControl"
                    [errorStateMatcher]="matcher"
                    matInput
                    required/>
                <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                    Please enter a valid email address
                </mat-error>
                <mat-error *ngIf="emailFormControl.hasError('required')">
                    Email is <strong>required</strong>
                </mat-error>
            </mat-form-field>
            <mat-form-field class="">
                <textarea
                    placeholder="Body"
                    style="height: 90px;; margin-left: 20px;"
                    [formControl]="bodyFormControl"
                    [errorStateMatcher]="matcher"
                    matInput
                    required>
                </textarea>
                <mat-error *ngIf="bodyFormControl.hasError('required')">
                    Body is <strong>required</strong>
                </mat-error>
            </mat-form-field>
            <input type="submit" style="margin-bottom: 30px; width: 50%; align-self: center;"/>
        </form>
    </mat-card>
</div>

