<div style="height: 100%; overflow: scroll;">
    <div class="half-panel">
        <img
            src="https://web-static.wrike.com/cdn-cgi/image/width=900,format=auto/blog/content/uploads/2020/09/Exploring-the-Brave-New-World-of-Hybrid-Team-Management-1.jpg?av=unknown"
            class="top-img"/>
        <div class="float-title">
            <span>Our Team</span>
        </div>
    </div>
    <div class="half-panel">
        <mat-card class="team-card">
            <img src="../../assets/rolfHeadshot.png" class="card-img" />
            <div class="card-info">
                <span class="card-header">Rolf Locher - President</span>
                <span>Experienced Software Engineer with a demonstrated history of working in the computer software industry. Skilled in Python, Swift, HTML, CSS, and Angular. Strong engineering professional with a Bachelor of Science (B.S.) in Computer Engineering from Villanova University.</span>
                <div class="badge-bar">
                    <a
                        href="https://www.linkedin.com/in/rolf-locher-98301a10a/"
                        target="_blank">
                        <img class="badge" src="assets/linkedin.png"/>
                    </a>
                    <a
                        href="https://github.com/rolflocher"
                        target="_blank">
                        <img class="badge" src="assets/github.png"/>
                    </a>
                </div>
            </div>
        </mat-card>
    </div>
    <div class="half-panel">
        <mat-card class="team-card">
            <img src="../../assets/tonyHeadshot.png" class="card-img" />
            <div class="card-info">
                <span class="card-header">Carl Reiser - Vice President</span>
                <span>Co-founder of Cranberry Queues and well-rounded Software Engineer by trade. Experienced in AWS lambda, Polymer, Node, React and Vue. Cum Laude Villanova University graduate with a Bachelor of Science (B.S.) in Computer Engineering and a CS Minor.</span>
                <div class="badge-bar">
                    <a
                        href="https://www.linkedin.com/in/carlreiser/"
                        target="_blank">
                        <img class="badge" src="assets/linkedin.png"/>
                    </a>
                    <a
                        href="https://github.com/creiser2"
                        target="_blank">
                        <img class="badge" src="assets/github.png"/>
                    </a>
                </div>
            </div>
        </mat-card>
    </div>
    <div class="half-panel">
        <mat-card class="team-card">
            <img src="../../assets/johnnyHeadshot.png" class="card-img" />
            <div class="card-info">
                <span class="card-header">John Persichilli - Chairman</span>
                <span>Full-Stack Developer at Vanguard and Juice Integration. Co-Founder of Cranberry Queues Corporation with a passion for problem solving and creativity. Proficient with Angular, Postgres, Java, AWS API Gateway, and Javascript Functions.</span>
                <div class="badge-bar">
                    <a
                        href="https://www.linkedin.com/in/john-persichilli-bb4178130/"
                        target="_blank">
                        <img class="badge" src="assets/linkedin.png"/>
                    </a>
                    <a
                        href="https://github.com/JohnnyPersichilli"
                        target="_blank">
                        <img class="badge" src="assets/github.png"/>
                    </a>
                </div>
            </div>
        </mat-card>
    </div>
    <div class="half-panel">
        <mat-card class="team-card">
            <img src="../../assets/cenzoHeadshot.png" class="card-img" />
            <div class="card-info">
                <span class="card-header">Matthew Innocenzo - Board Secretary</span>
                <span>Software Engineer in System Integration & Test and Lockheed Martin. Experienced in software/hardware test development, MATLAB tool design, and EW research. Focused on delivering proficiencies in programming, systems and signal processing with strong communication skills.</span>
                <div class="badge-bar">
                    <a
                        href="https://www.linkedin.com/in/matthewinnocenzo/"
                        target="_blank">
                        <img class="badge" src="assets/linkedin.png"/>
                    </a>
                    <a
                        href="https://github.com/Matt-Innocenzo"
                        target="_blank">
                        <img class="badge" src="assets/github.png"/>
                    </a>
                </div>
            </div>
        </mat-card>
    </div>
</div>
