import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FirebaseService, FirebaseEmail, FirebaseEmailMessage } from '../firebase.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  subjectFormControl = new FormControl('', []);
  emailFormControl = new FormControl('', [Validators.required, Validators.email]);
  bodyFormControl = new FormControl('', [Validators.required]);

  matcher = new CommonErrorStateMatcher();

  constructor(
    private _snackBar: MatSnackBar,
    public firebaseService: FirebaseService) { }

  ngOnInit(): void { }

  submit(): void {
    if(this.emailFormControl.hasError('email') ||
      this.emailFormControl.hasError('required') ||
      this.bodyFormControl.hasError('required') ||
      !this.emailFormControl.value ||
      !this.bodyFormControl.value) {
      return;
    }
    let controls = [
      this.subjectFormControl,
      this.emailFormControl,
      this.bodyFormControl
    ];
    for(let control of controls) {
      control.disable();
    }
    let msg = {
      to: ['cloudqueuedev@gmail.com'],
      message: {
        subject: this.emailFormControl.value + ": " + this.subjectFormControl.value,
        text: '',
        html: this.bodyFormControl.value,
      } as FirebaseEmailMessage,
    } as FirebaseEmail
    this.firebaseService.postMessage(msg, success => {
      for(let control of controls) {
        control.enable();
      }
      if(success === true) {
        for(let control of controls) {
          control.setValue('');
          control.setErrors(null);
        }
        let snackBarRef = this._snackBar.open('Message sent', 'Continue');
        setTimeout( ()=> {
          if(snackBarRef) {
            snackBarRef.dismiss();
          }
        }, 5000)
      } else {
        let snackBarRef = this._snackBar.open('Message failed to send', 'Continue');
        setTimeout( ()=> {
          if(snackBarRef) {
            snackBarRef.dismiss();
          }
        }, 5000)
      }
    });
  }
  
}

export class CommonErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}