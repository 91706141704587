import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-stripe-onboarding-refresh',
  templateUrl: './stripeOnboardingRefresh.component.html',
  styleUrls: ['./stripeOnboardingRefresh.component.css']
})
export class StripeOnboardingRefreshComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    console.log('yeet');
    window.location.href = 'CranberryQueue://stripeonboardingrefresh';
  }

}
