import { Injectable } from '@angular/core';
// const firebase = require("firebase");
// // Required for side-effects
// require("firebase/firestore");

// import * as firebase from 'firebase/app';
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  db: any;

  constructor() {
    var firebaseConfig = {
      apiKey: "AIzaSyAqNBiJvrDlNn4XmP81zp_9IlUFdHrRj8o",
      authDomain: "cranberry-queues-production.firebaseapp.com",
      databaseURL: "https://cranberry-queues-production.firebaseio.com",
      projectId: "cranberry-queues-production",
      storageBucket: "cranberry-queues-production.appspot.com",
      messagingSenderId: "1069112903616",
      appId: "1:1069112903616:web:e8b04e35514bc16c012a25",
      measurementId: "G-MQFVD8DPJ3"
    };
    firebase.initializeApp(firebaseConfig);
    this.db = firebase.firestore();
    firebase.auth().signInAnonymously()
      .then((user) => {
        
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log(errorCode + errorMessage);
      });
  }

  postMessage(msg: FirebaseEmail, callback: (success: boolean) => void): void {
    this.db.collection('support').withConverter(this.messageConverter).add(
      msg
    ).then((doc: firebase.firestore.DocumentReference) => {
      callback(true);
    }).catch((err: Error) => {
      console.log(err);
      callback(false);
    });
  }

  messageConverter = {
    toFirestore: function(msg: FirebaseEmail) {
      return {
        to: msg.to,
        message: msg.message
      };
    },
    fromFirestore: function(snapshot: firebase.firestore.QueryDocumentSnapshot, options: firebase.firestore.SnapshotOptions){
      const data = snapshot.data(options);
      return { to: data.to, message: data.message } as FirebaseEmail;
    }
  };
}

export interface FirebaseEmail {
  to: string[];
  message: FirebaseEmailMessage;
}

export interface FirebaseEmailMessage {
  subject: string;
  text: string;
  html: string;
}