<div style="display: block; width: 100%;">
    <div class="main">
        <div class="toolbar" role="banner">
            <div class="temp-container">
                <div class="brand-panel">
                    <nav>
                      <a routerLink="">
                        <img
                          width="58"
                          alt="Cranberry Logo"
                          src="assets/180.png"
                        />
                      </a>
                    </nav>
                    <nav>
                      <a routerLink="" class="custom-link">
                        <span>Cranberry Queues Corp</span>
                      </a>
                    </nav>
                  </div>
                  <div class="action-panel">
                    <!-- <nav>
                        <a routerLink="/about" class="custom-link">About</a>
                    </nav>
                    <nav>
                        <a routerLink="/team" class="custom-link">Team</a>
                    </nav>
                    <nav>
                        <a routerLink="/products" class="custom-link">Products</a>
                    </nav> -->
                    <nav>
                      <a routerLink="/investors" class="custom-link">Investors</a>
                    </nav>
                    <!-- <nav>
                        <a routerLink="/contact" class="custom-link">Contact Us</a>
                    </nav> -->
                  </div>
            </div>
          
            <!-- <a class="ces-call-to-action" href="http://www.digital.ces.tech/">
              Explore CES<sup>®</sup> 2022 &#8250;
            </a> -->
        </div>
        <div class="p1">
            <router-outlet></router-outlet>
        </div>
    </div>
    <div class="footer">
        <span>© Cranberry Queues Corp.</span>
        <span>New York, NY</span>
        <span>June, 2020</span>
    </div>
</div>
