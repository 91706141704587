import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-stripe-onboarding-return',
  templateUrl: './stripeOnboardingReturn.component.html',
  styleUrls: ['./stripeOnboardingReturn.component.css']
})
export class StripeOnboardingReturnComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    console.log('yeet 00');
    window.location.href = 'CranberryQueue://stripeonboardingreturn';
  }

}
