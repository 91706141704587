import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AboutComponent } from './about/about.component';
import { LandingComponent } from './landing/landing.component';
import { ContactComponent } from './contact/contact.component';
import { TeamComponent } from './team/team.component';
import { ProductComponent } from './product/product.component';
import { InvestorsComponent } from './investors/investors.component';
import { StripeOnboardingRefreshComponent } from './stripe/stripeOnboardingRefresh/stripeOnboardingRefresh.component';
import { StripeOnboardingReturnComponent } from './stripe/stripeOnboardingReturn/stripeOnboardingReturn.component';

const routes: Routes = [
  { path: '', component: LandingComponent },
  { path: 'about', component: AboutComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'team', component: TeamComponent },
  { path: 'products', component: ProductComponent },
  { path: 'investors', component: InvestorsComponent },
  { path: 'stripeonboardingrefresh', component: StripeOnboardingRefreshComponent },
  { path: 'stripeonboardingreturn', component: StripeOnboardingReturnComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
