<div class="container">
    <div class="bannerContainer">
        <img class="bannerLogo" alt="Cranberry Logo" src="assets/180.png"/>
        <h3 class="bannerText"> Cranberry Queues </h3>
    </div>

    <!-- <div class="back-imageContainer">
        <iframe class="video"
            id="video-embed"
            [src]="safeUrl"
            frameborder="0"
            allow="autoplay"
            data-ready="true">
        </iframe>
    </div> -->

    <div *ngFor="let src of this.backroundImgSrcs; let i = index">
        <div class="back-imageContainer">
            <img src="{{src}}" class="back-image"/>
        </div>
    </div>

    <div class="reviewContainer">
        <p class="reviewHeaderText">Testimonials from our perfect 5 star rating!</p>
        <div class="singleReviewContainer">
            <div class=reviedHeaderContainer>
                <p class="reviewTitleText">"Incredible music app"</p>
                <img class="fiveStarImage" src="assets/5stars.png"/>
            </div>
            <p class="reviewDetailText">"This app has revoluntionized the way my friends listen to music. Cranberry Queues has permantly shifted listening to music in groups."</p>
        </div>
        <div class="singleReviewContainer">
            <div class=reviedHeaderContainer>
                <p class="reviewTitleText">"No More Stress Over AuX"</p>
                <img class="fiveStarImage" src="assets/5stars.png"/>
            </div>
            <p class="reviewDetailText">"This is perfect for the friend who always seems to be stuck with the AUX responsibility- if you dont like my music addd your own!!!"</p>
        </div>
        <div class="singleReviewContainer">
            <div class=reviedHeaderContainer>
                <p class="reviewTitleText">"Finally!"</p>
                <img class="fiveStarImage" src="assets/5stars.png"/>
            </div>
            <p class="reviewDetailText">"I've been waiting for years for this app. After years of listening to terrible rap music in my hockey teams locker room. Now, I can finally request that we play the hits..."</p>
        </div>
    </div>

    <div class="contactContainer">
        <div class="inquiryInfoContainer">
            <h3 class="headerText">
                Cranberry Queues is currently in the process of expansion and are looking for investors to help fund the rapid growth. 
            </h3>
            <h3 class="headerText">
                Questions, comments? Get in touch with us and we’ll get back to you ASAP.
            </h3>
            <h3 class="headerText">
                Or click <a href="/team" class="meetTheTeamText">here</a>  to meet the team!
            </h3>
        </div>
        <div class="inquiryFormContainer">
            <div class="inquiryWhiteBackround">
                <form class="inquiryForm">
                    <mat-form-field class="formField" style="padding-top: 10px;" appearance="outline">
                        <mat-label>Name</mat-label>
                        <input 
                            matInput
                            placeholder=""
                            required
                            [formControl]="nameFormControl"
                            [errorStateMatcher]="matcher">
                        <mat-error *ngIf="nameFormControl.hasError('required')">
                            Name is <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="formField" appearance="outline">
                        <mat-label>Email</mat-label>
                        <input 
                            type="email"
                            matInput
                            placeholder=""
                            required
                            [formControl]="emailFormControl"
                            [errorStateMatcher]="matcher">
                        <mat-error *ngIf="emailFormControl.hasError('required')">
                            Email is <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="formField" appearance="outline">
                        <mat-label>Reason for Inquiry</mat-label>
                        <input 
                            matInput
                            placeholder="Ex. Investment Opportunities"
                            name="reason"
                            required
                            [formControl]="reasonFormControl"
                            [errorStateMatcher]="matcher">
                        <mat-error *ngIf="reasonFormControl.hasError('required')">
                            Body is <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
            
                    <mat-form-field class="formField" appearance="outline">
                        <mat-label>Leave a comment</mat-label>
                        <textarea
                            placeholder=""
                            style="height: 75px;"
                            [formControl]="bodyFormControl"
                            [errorStateMatcher]="matcher"
                            matInput
                            required>
                        </textarea>
                        <mat-error *ngIf="bodyFormControl.hasError('required')">
                            Body is <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                    <mat-card-actions style="margin-left: 10%; margin-right: 10%; padding-bottom: 10px;">
                        <button style="width: 100%;" mat-raised-button type="submit" [disabled]="!isValidInput()" (click)="submit()" color="#1976d2">Send</button>
                    </mat-card-actions>
                </form>
            </div>
        </div>
    </div>
 
</div>
