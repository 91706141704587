import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FirebaseService, FirebaseEmail, FirebaseEmailMessage } from '../firebase.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-about',
  templateUrl: './investors.component.html',
  styleUrls: ['./investors.component.css'],
})
export class InvestorsComponent implements OnInit {

  nameFormControl = new FormControl('', [Validators.required]);
  emailFormControl = new FormControl('', [Validators.required, Validators.email]);
  bodyFormControl = new FormControl('', [Validators.required]);
  reasonFormControl = new FormControl('', [Validators.required]);

  matcher = new CommonErrorStateMatcher();


  videoUrl = 'https://www.youtube.com/embed/nBh04BEtbvA?autoplay=1&mute=1';
  safeUrl: SafeResourceUrl;

  constructor(
    private _snackBar: MatSnackBar,
    public firebaseService: FirebaseService,
    private _sanitizer: DomSanitizer) {
      this.safeUrl = this._sanitizer.bypassSecurityTrustResourceUrl(this.videoUrl);
    }

  backroundImgSrcs: String[] = ["assets/missionStatementSlide.png", "assets/whyNowSlide.png", "assets/infoSlide.png","assets/differentiatorsSlide.png"]

  ngOnInit(): void {
  }

  isValidInput() {
    if(this.nameFormControl.hasError('required') ||
      this.emailFormControl.hasError('email') ||
      this.emailFormControl.hasError('required') ||
      this.bodyFormControl.hasError('required') ||
      this.reasonFormControl.hasError('required') ||
      !this.nameFormControl.value ||
      !this.emailFormControl.value ||
      !this.bodyFormControl.value ||
      !this.reasonFormControl.value) {
      return false;
    } else {
      return true;
    }
  }

  submit(): void {
    if(!this.isValidInput) {
      return;
    }

    let controls = [
      this.nameFormControl,
      this.emailFormControl,
      this.bodyFormControl,
      this.reasonFormControl,
    ];
    for(let control of controls) {
      control.disable();
    }
    let msg = {
      to: ['cloudqueuedev@gmail.com'],
      message: {
        subject: this.nameFormControl.value + "(" + this.emailFormControl.value + ") is requesting information about " + this.reasonFormControl.value,
        text: '',
        html: this.bodyFormControl.value,
      } as FirebaseEmailMessage,
    } as FirebaseEmail
    this.firebaseService.postMessage(msg, success => {
      for(let control of controls) {
        control.enable();
      }
      if(success === true) {
        for(let control of controls) {
          control.setValue('');
          control.setErrors(null);
        }
        let snackBarRef = this._snackBar.open('Message sent', 'Continue');
        setTimeout( ()=> {
          if(snackBarRef) {
            snackBarRef.dismiss();
          }
        }, 5000)
      } else {
        let snackBarRef = this._snackBar.open('Message failed to send', 'Continue');
        setTimeout( ()=> {
          if(snackBarRef) {
            snackBarRef.dismiss();
          }
        }, 5000)
      }
    });
  }
}

export class CommonErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
