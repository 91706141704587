<div class="container">
    <img
        src="../../assets/product.png"
        class="product-img"/>
    <div class="product-info">
        <span class="product-title">Cranberry: Explore New Music</span>
        <span class="product-subtitle">Audio isolation is over. Listen with anyone, anywhere, in real time. Add songs, vote for what plays next, and chat with friends.</span>
        <a
            href="https://apps.apple.com/us/app/cranberry-explore-new-music/id1484019324?itsct=apps_box&amp;itscg=30200"
            style="display: inline-block; overflow: hidden; border-radius: 13px; width: 250px; height: 83px;">
            <img
                src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-US?size=250x83&amp;releaseDate=1580947200&h=0654767dbef00453bfe90959f581c1a8"
                alt="Download on the App Store"
                style="border-radius: 13px; width: 250px; height: 83px;"/>
        </a>
    </div>
    <img
        class="background"
        src="assets/geometric-minimalist-mountains.jpeg"/>
</div>
